import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Select } from 'antd';
import SelectInput from '../../UI/inputs/Select';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import useLocale from '../../hooks/useLocale';

const { Option } = Select;
interface LanguageSwitcherProps {
  variant?: string;
}

interface LanguageSwitcherWrapperProps {
  readonly dark: boolean;
}

const getFlagEmoji = (code: string) => {
  const codePoints = (code === 'en' ? 'gb' : code).toUpperCase().split('').map(x => 0x1f1a5 + x.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

const LanguageSwitcherWrapper = styled.div<LanguageSwitcherWrapperProps>`
  display: flex;
  text-transform: capitalize;

  .ant-select {
    flex-grow: 1;
  }
`;

const localeList = [
  {
    title: 'english',
    code: 'en',
  },
  {
    title: 'dutch',
    code: 'nl',
  },
  {
    title: 'french',
    code: 'fr',
  },
  {
    title: 'german',
    code: 'de',
  },
];
const LanguageSwitcher = ({ variant }: LanguageSwitcherProps) => {
  const { sessionLocale: lang, setBrowserLocale } = useLocale();
  const [locale, setLocale] = useState(lang);
  const handleLocale = (lang: string) => {
    setLocale(lang);
    setBrowserLocale(lang);
    axios.defaults.headers.common['Accept-Language'] = lang;
  };
  const handleClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <LanguageSwitcherWrapper
      dark={variant ? variant === 'dark' : false}
      onClick={handleClick}
    >
      <SelectInput
        showSearch
        className="custom-select"
        optionFilterProp="children"
        showArrow={true}
        suffixIcon={<ArrowDownIcon />}
        filterOption={true}
        notFoundContent={null}
        onSelect={handleLocale}
        value={locale}
      >
        {localeList.map((l) => (
          <Option key={l.code} value={l.code}>
            {getFlagEmoji(l.code)} {l.title}
          </Option>
        ))}
      </SelectInput>
    </LanguageSwitcherWrapper>
  );
};

export default LanguageSwitcher;
